import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { AnimatePresence, motion } from "framer-motion"

import Grid from "./../Grid"
import TextRenderer from "./../TextRenderer"

import { padLeft } from "./../../utils/helpers"

const List = styled(motion.ul)`
  position: relative;

  width: 100%;
  height: 10rem;

  border-bottom: 1px solid rgba(23, 21, 21, 0.15);

  overflow: hidden;
`

const Item = styled(motion.li)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
`

const StyledTextRenderer = styled(TextRenderer)`
  text-align: center;

  p {
    font-family: ${props => props.theme.fontFamily.estiLight};
    font-size: 1.6rem;
    line-height: 2rem;

    color: ${props => props.theme.colors.greyDark};
  }

  ${props => props.theme.above.desktop`
        text-align: left;

        p {
            font-size: 2.5rem;
            line-height: 3rem;
        }
    `}
`

const Pagination = styled.div`
  display: none;
  position: absolute;

  bottom: -5rem;
  right: 0;

  font-family: ${props => props.theme.fontFamily.estiRegular};
  font-size: 1.6rem;

  color: currentColor;

  ${props => props.theme.above.desktop`
        display: block;

        right: 4rem;
    `}
`

const Current = styled.span`
  display: inline-block;
  vertical-align: middle;
`

const Line = styled.span`
  display: inline-block;
  vertical-align: middle;

  width: 2rem;
  height: 0.1rem;

  margin: 0 1rem;

  background-color: currentColor;

  opacity: 0.25;
`

const Total = styled.span`
  display: inline-block;
  vertical-align: middle;

  opacity: 0.25;
`

const QuotesCarousel = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex(currentIndex =>
        currentIndex + 1 > data.items.length - 1 ? 0 : currentIndex + 1
      )
    }, 5000)
    return () => clearInterval(interval)
  }, [currentIndex])

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })

  return (
    <Grid type="row">
      <Grid type="column" columns={10} offset={1}>
        <List
          ref={ref}
          animate={inView ? "after" : "before"}
          variants={{
            before: {
              opacity: 0,
              y: 100,
            },
            after: {
              opacity: 1,
              y: 0,
            },
          }}
          transition={{
            duration: 1,
            damping: 100,
            ease: "circOut",
          }}
        >
          {data?.items?.map((item, index) => {
            return (
              <AnimatePresence key={index}>
                {currentIndex == index && (
                  <Item
                    key={index}
                    initial={{
                      opacity: 0,
                      y: 100,
                      skewX: 3,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      skewX: 0,
                    }}
                    exit={{
                      opacity: 0,
                      y: -50,
                      skewX: 3,
                    }}
                    transition={{
                      duration: 0.5,
                      damping: 100,
                      ease: "circOut",
                    }}
                  >
                    <StyledTextRenderer data={item.content} />
                  </Item>
                )}
              </AnimatePresence>
            )
          })}
        </List>
        <Pagination>
          <Current>{padLeft(currentIndex + 1)}</Current>
          <Line />
          <Total>{padLeft(data.items.length)}</Total>
        </Pagination>
      </Grid>
    </Grid>
  )
}

export default QuotesCarousel
