import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { AnimatePresence, motion } from "framer-motion"

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  height: 10rem;

  margin: 2rem auto 4rem auto;

  overflow: hidden;

  ${props => props.theme.above.desktop`
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        height: 8rem;
    
        max-width: 100rem;
        margin: 15rem auto 25rem auto;
    `}
`

const Numbers = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  ${props => props.theme.above.desktop`
        width: 40%;
    `}
`

const Sentences = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  ${props => props.theme.above.desktop`
        width: 60%;
    `}
`

const Number = styled(motion.span)`
  display: block;

  position: absolute;

  top: 0;
  left: 0;

  width: 100%;

  font-family: ${props => props.theme.fontFamily.proximaBold};
  font-size: 5rem;
  line-height: 5rem;

  text-align: center;

  color: ${props => props.theme.colors.greyDark};

  ${props => props.theme.above.desktop`
        left: initial;
        right: 2.5rem;

        width: auto;

        font-size: 10rem;
        line-height: 8rem;

        text-align: left;
    `}
`

const Sentence = styled(motion.p)`
  display: block;

  position: absolute;

  top: 0;
  left: 0;

  width: 100%;

  font-family: ${props => props.theme.fontFamily.estiLight};
  font-size: 1.6rem;
  line-height: 5rem;

  text-align: center;

  color: ${props => props.theme.colors.greyDark};

  ${props => props.theme.above.desktop`
        left: 2.5rem;

        width: auto;

        font-size: 2.5rem;
        line-height: 8rem;

        text-align: left;
    `}
`

const NumbersCarousel = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentIndex(currentIndex =>
        currentIndex + 1 > data.items.length - 1 ? 0 : currentIndex + 1
      )
    }, 5000)
    return () => clearInterval(interval)
  }, [currentIndex])

  const [wrapperRef, wrapperInView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })

  return (
    <Wrapper
      ref={wrapperRef}
      animate={wrapperInView ? "after" : "before"}
      variants={{
        before: {
          opacity: 0,
          y: 50,
        },
        after: {
          opacity: 1,
          y: 0,
        },
      }}
      transition={{
        duration: 1,
        damping: 100,
        ease: "circOut",
      }}
    >
      <Numbers>
        {data?.items?.map((item, index) => {
          return (
            <AnimatePresence key={index}>
              {index == currentIndex && (
                <Number
                  key={index}
                  initial={{
                    opacity: 0,
                    y: -100,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  exit={{
                    opacity: 0,
                    y: 100,
                  }}
                  transition={{
                    duration: 0.5,
                    damping: 100,
                    ease: "circOut",
                  }}
                >
                  {item.number}
                </Number>
              )}
            </AnimatePresence>
          )
        })}
      </Numbers>
      <Sentences>
        {data?.items?.map((item, index) => {
          return (
            <AnimatePresence key={index}>
              {index == currentIndex && (
                <Sentence
                  key={index}
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  exit={{
                    opacity: 0,
                    y: -100,
                  }}
                  transition={{
                    duration: 0.5,
                    damping: 100,
                    ease: "circOut",
                  }}
                >
                  {item.title}
                </Sentence>
              )}
            </AnimatePresence>
          )
        })}
      </Sentences>
    </Wrapper>
  )
}

export default NumbersCarousel
