import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import WorldMapSvg from './../images/graphics/worldmap.svg'
import Grid from './Grid'

const Wrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
`

const StyledGrid = styled(Grid)`
    margin: 4rem 0;

    ${props => props.theme.above.desktop`
        margin: 5rem 0 10rem 0;
    `}
`

const StyledWorldMap = styled(WorldMapSvg)`
    width: 100%;
`

const WorldMap = () => {
    
    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    })
    
    return (
        <Wrapper 
            ref={ref}
            animate={inView ? 'after' : 'before'}
            variants={{
                before: {
                    opacity: 0,
                    y: 50
                },
                after: {
                    opacity: 1,
                    y: 0
                }
            }}
            transition={{
                duration: 0.5,
                damping: 100,
                ease: 'circOut'
            }}
        >
            <StyledGrid type='row'>
                <Grid type='column' columns={10} offset={1}>
                    <StyledWorldMap />
                </Grid>
            </StyledGrid>
        </Wrapper>
    )
}

export default WorldMap
