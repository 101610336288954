import React from 'react'
import { graphql } from 'gatsby'

import Section from './../components/Section'
import Seo from '../components/Seo'

const PageTemplate = ({
    pageContext: {
        layout: {
            langSlug
        }
    },
    data: {
        contentfulPage: {
            title,
            seoTitle,
            seoKeywords,
            seoDescription,
            sections
        }
    }
}) => {
    return (
        <>
            <Seo
                title={seoTitle || title}
                keywords={seoKeywords && seoKeywords.join(', ')}
                description={seoDescription && seoDescription.seoDescription}
            />
            {sections.map((data, i) =>
                <Section
                    key={i}
                    data={data}
                    lang={langSlug}
                />
            )}
        </>
    )
}

export const query = graphql`
	query($id: String!) {
		contentfulPage(id: {eq: $id}) {
            title
            seoTitle
            seoKeywords
            seoDescription {
                seoDescription
            }
            sections {
                title
                backgroundColor
                columns
                type
                components {
                    __typename ... on Node {
                        ... on ContentfulComponentBackgroundText {
                            ...ComponentBackgroundText
                        }
                        ... on ContentfulComponentCarousel {
                            ...ComponentCarousel
                        }
                        ... on ContentfulComponentHeroBanner {
                            ...ComponentHeroBanner
                        }
                        ... on ContentfulComponentImage {
                            ...ComponentImage
                        }
                        ... on ContentfulComponentList {
                            ...ComponentList
                        }
                        ... on ContentfulComponentSuppliers {
                            ...ComponentSuppliers
                        }
                        ... on ContentfulComponentText {
                            ...ComponentText
                        }
                        ... on ContentfulComponentThumbnail {
                            ...ComponentThumbnail
                        }
                        ... on ContentfulComponentTitle {
                            ...ComponentTitle
                        }
                        ... on ContentfulComponentVideo {
                            ...ComponentVideo
                        }
                        ... on ContentfulComponentWorldMap {
                            ...ComponentWorldMap
                        }
                    }
                }
            }
        }
	}
`

export default PageTemplate
