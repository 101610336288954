import React, { useState, useEffect, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import theme from './../../styles/theme'

import ImageOverlay from './../Shared/ImageOverlay'
import ArrowSvg from './../../images/graphics/arrow-right.svg'

const Wrapper = styled(motion.div)`
    position: relative;

    width: 100%;
    height: 100%;

    padding-bottom: 2rem;

    ${props => props.type == 'fade' && `
        margin: 4rem auto -4rem auto;
        padding-bottom: 0;
    `}

    ${props => props.theme.above.desktop`
        margin-top: 10rem;
        padding-bottom: 0;

        ${props.type == 'fade' && `
            max-width: 100rem;
            margin: 10rem auto 0 auto;
        `}
    `}
`

const Item = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.1);
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;
`

const Navigation = styled.div`
    display: none;
    justify-content: flex-end;
    align-items: center;

    position: absolute;

    bottom: 0;
    right: 0;

    transform: translateY(100%);

    width: 100%;
    height: 15rem;

    padding-right: 5rem;

    ${props => props.type == 'fade' && `
        top: 0;

        transform: translateY(-100%);

        height: 10rem;

        padding-right: 0;

        polygon {
            fill: ${props.theme.colors.white};
        }
    `}

    ${props => props.theme.above.desktop`
        display: flex;
    `}
`

const ArrowStyles = css`
    width: 2rem;
`

const Button = styled.button`
    padding: 2rem;

    cursor: pointer;

    opacity: 0;

    transition: opacity 0.25s ${props => props.theme.transitions.cubic};

    ${props => props.type == 'fade' ? `
        opacity: 0.25;

        &:hover {
            opacity: 1;
        }
    ` : `
        ${props.visible && `
            opacity: 0.25;

            &:hover {
                opacity: 1;
            }
        `}  
    `}
`

const StyledArrow = styled(ArrowSvg)`
    ${ArrowStyles}

    ${props => props.direction == 'left' && `
        transform: rotate(180deg);
        transform-origin: center;
    `}
`

const ImagesCarousel = ({
    data,
    type,
    className,
    backgroundColor
}) => {

    let params
    const [swiper, setSwiper] = useState(null)
    const [currentIndex, updateCurrentIndex] = useState(type == 'fade' ? 0 : 1)

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    })

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev()
        }
    }

    const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [swiper])

    useEffect(() => {
        if (swiper !== null) {
            swiper.on('slideChange', updateIndex)
        }

        return () => {
            if (swiper !== null) {
                swiper.off('slideChange', updateIndex)
            }
        };
    }, [swiper, updateIndex]);

    if (type == 'fade') {
        params = {
            effect: 'fade',
            loop: data.images.length > 1 ? true : false,
            preloadImages: true,
            autoplay: {
                delay: data.images.length > 1 ? 5000 : 0,
                disableOnInteraction: false,
            }
        }
    } else {
        params = {
            centeredSlides: 'true',
            grabCursor: true,
            initialSlide: 1,
            grabCursor: true,
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                    loop: true
                },
                1023: {
                    slidesPerView: 1.5,
                    spaceBetween: 100,
                    loop: false
                }
            }
        }
    }

    return (
        <Wrapper 
            ref={ref} 
            type={type}
            className={className}
            animate={inView ? 'after' : 'before'}
            variants={{
                before: {
                    opacity: 0,
                    y: 50
                },
                after: {
                    opacity: 1,
                    y: 0
                },
            }}
            transition={{
                duration: 1,
                damping: 400,
                ease: 'circOut'
            }}
        >
            <Swiper {...params} getSwiper={setSwiper}>
                {data.images.map((image, index) => {
                    return (
                        <Item key={index} className='item'>
                            <StyledImage
                                key={index}
                                fluid={image.fluid}
                                alt={image.title}
                            />
                        </Item>
                    )
                })}
            </Swiper>
            {type == 'fade' && <ImageOverlay inView={inView} backgroundColor={backgroundColor} />}
            {data.images.length > 1 && <Navigation type={type}>
                <Button type={type} visible={currentIndex != 0} onClick={goPrev}>
                    <StyledArrow direction='left' />
                </Button>
                <Button type={type} visible={currentIndex != (data.images.length - 1)} onClick={goNext}>
                    <StyledArrow direction='right' />
                </Button>
            </Navigation>}
        </Wrapper>
    )
}

export default ImagesCarousel
