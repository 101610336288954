import React, { useState } from 'react'
import styled from 'styled-components'

import theme from './../styles/theme'

import HeroBanner from './HeroBanner'
import BackgroundText from './BackgroundText'
import Title from './Title'
import Text from './Text'
import Carousel from './Carousel'
import Image from './Image'
import List from './List'
import Suppliers from './Suppliers'
import Thumbnail from './Thumbnail'
import InlineVideo from './InlineVideo'
import WorldMap from './WorldMap'
import Grid from './Grid'
import { getColor } from '../utils/helpers'
import useThrottle from '../utils/useThrottle'

const StyledSection = styled.section`
    background-color: ${props => props.backgroundColor};

    color: ${props => props.color};

    a {
        color: ${props => props.color};
    }

    ${props => props.type == 'Normal' && `
        padding: 4rem 0;
    `}

    ${props => props.type == 'Full Width' && `
        padding: 4rem 0;
    `}

    ${props => props.theme.above.desktop`
        padding: 0;

        ${props.type == 'Normal' && `
            padding: 15rem 0;
        `}

        ${props.type == 'Full Width' && `
            padding: 15rem 0;

            ${props.columns == '1/2' && `
                .inner {
                    display: flex;
                    align-items: center;
                }
            `}
        `}

        ${props.type != 'Banner' && `
            &:first-of-type {
                padding: 0 0 15rem 0;

                ${props.type == 'Normal' && `
                    .text-block {
                        margin-bottom: 10rem;
                    }
                `}
            }
        `}
    `}
`

const StyledGrid = styled(Grid)`
    ${props => props.theme.above.desktop`
        padding: 0;
    `}
`

const FullInner = styled.div`
    width: 100%;
`

const findComponent = (components, layout, backgroundColor, lang) => {
    return (
        components.map((component, i) =>
            (() => {
                switch (component.__typename) {
                    case 'ContentfulComponentBackgroundText': return (
                        <BackgroundText 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component}
                            backgroundColor={backgroundColor}
                        />
                    )
                    case 'ContentfulComponentHeroBanner': return (
                        <HeroBanner 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                    case 'ContentfulComponentTitle': return (
                        <Title 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                    case 'ContentfulComponentText': return (
                        <Text 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                    case 'ContentfulComponentCarousel': return (
                        <Carousel 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                            backgroundColor={backgroundColor}
                        />
                    )
                    case 'ContentfulComponentImage': return (
                        <Image 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component}
                            backgroundColor={backgroundColor}
                        />
                    )
                    case 'ContentfulComponentList': return (
                        <List 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                            backgroundColor={backgroundColor}
                        />
                    )
                    case 'ContentfulComponentSuppliers': return (
                        <Suppliers 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                    case 'ContentfulComponentThumbnail': return (
                        <Thumbnail 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                    case 'ContentfulComponentVideo': return (
                        <InlineVideo 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                            backgroundColor={backgroundColor}
                        />
                    )
                    case 'ContentfulComponentWorldMap': return (
                        <WorldMap 
                            key={i} 
                            lang={lang}
                            layout={layout} 
                            data={component} 
                        />
                    )
                }
            })()
        )
    )
}

const Section = ({
    lang,
    data: {
        title,
        backgroundColor,
        columns,
        negativeMargin,
        components,
        type
    }
}) => {

    const [isViewport, setViewport] = useState(typeof window != 'undefined' && window.innerWidth > theme.breakpoints.desktop ? 'desktop' : 'mobile')

    useThrottle(callbackData => {
        const { previousWindowWidth } = callbackData
        const isDesktop = previousWindowWidth > theme.breakpoints.desktop ? 'desktop' : 'mobile'

        setTimeout(() => {
            setViewport(isDesktop)
        }, 100)
    })

    let blockSize, gridSize, gridOffset

    if (columns == '1/1') {
        blockSize = '100%'
        gridSize = 6
        gridOffset = 3
    } else if (columns == '1/2') {
        blockSize = isViewport == 'desktop' ? '50%' :'100%'
        gridSize = type == 'Full Width' ? 10 : 12
        gridOffset = type == 'Full Width' ? 1 : 0
    } else if (columns == '1/3') {
        blockSize = isViewport == 'desktop' ? `${100 / 3}%` : '100%'
        gridSize = 8
        gridOffset = 2
    } else {
        blockSize = isViewport == 'desktop' ? '25%' : '100%'
        gridSize = 8
        gridOffset = 2
    }

    const layout = {
        columns: columns,
        blockSize: blockSize,
        gridSize: gridSize,
        gridOffset: gridOffset
    }

    if (type == 'Banner') {
        return (
            <StyledSection
                type={type}
                columns={columns}
                backgroundColor={getColor(theme, backgroundColor)}
                color={backgroundColor == 'GreyDark' ? theme.colors['white'] : theme.colors['greyDark']}
                data-id={title}
            >
                {findComponent(components, layout, getColor(theme, backgroundColor), lang)}
            </StyledSection>    
        )
    } else if (type == 'Full Width') {
        return (
            <StyledSection
                type={type}
                columns={columns}
                backgroundColor={getColor(theme, backgroundColor)}
                color={backgroundColor == 'GreyDark' ? theme.colors['white'] : theme.colors['greyDark']}
                data-id={title}
            >
                <FullInner className='inner'>
                    {findComponent(components, layout, getColor(theme, backgroundColor), lang)}
                </FullInner>
            </StyledSection>
        )
    } else {
        return (
            <StyledSection
                type={type}
                columns={columns}
                backgroundColor={getColor(theme, backgroundColor)}
                color={backgroundColor == 'GreyDark' ? theme.colors['white'] : theme.colors['greyDark']}
                data-id={title}
            >
                <StyledGrid type='row' negativeMargin={negativeMargin}>
                    {findComponent(components, layout, getColor(theme, backgroundColor), lang)}
                </StyledGrid>
            </StyledSection> 
        )
    }
}

export default Section
