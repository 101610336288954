import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

function useThrottle(callback) {
    const [, seWindowWidth] = useState(0)
    let previousWindowWidth = 0

    function handleDocumentScroll() {
        const windowWidth = typeof window != 'undefined' && window.innerWidth

        seWindowWidth(previousSize => {
            previousWindowWidth = previousSize
            return windowWidth
        })

        callback({ previousWindowWidth, windowWidth })
    }

    const handleDocumentSizeThrottled = throttle(handleDocumentScroll, 250)

    useEffect(() => {
        window.addEventListener('resize', handleDocumentSizeThrottled)

        return () =>
            window.removeEventListener('resize', handleDocumentSizeThrottled)
    }, [])
}

export default useThrottle