import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import Grid from './Grid'
import TextRenderer from './TextRenderer'
import Title from './Title';

const StyledText= styled.div`
    display: flex;
    flex-direction: column;
    
    width: ${props => props.blockSize};

    text-align: ${props => props.alignment};
`

const Text = ({
    layout,
    lang,
    data: {
        alignment,
        contentTitle,
        content
    }
}) => {
    
    let needSpace = content && content.json.content[0].nodeType == 'blockquote' ? true : false

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.15
    })

    return (
        <StyledText ref={ref} blockSize={layout.blockSize}>
            <Grid type='row'>
                <Grid type='column' columns={needSpace ? 8 : layout.gridSize} offset={needSpace ? 2 : layout.gridOffset}>
                    {contentTitle && (
                        <Title 
                            type={contentTitle.type} 
                            data={contentTitle} 
                            lang={lang}
                        />
                    )}
                    <TextRenderer 
                        lang={lang}
                        data={content} 
                        alignment={alignment && alignment.toLowerCase()}
                        animate={inView ? 'after' : 'before'}
                    />
                </Grid>
            </Grid>
        </StyledText>
    )
}

export default Text
