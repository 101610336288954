import React, { useState, useRef, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
// import Vimeo from "@u-wave/react-vimeo"
import ReactPlayer from "react-player"
import styled from "styled-components"
import Img from "gatsby-image"

import Grid from "./Grid"
import ImageOverlay from "./Shared/ImageOverlay"

const Wrapper = styled.div`
  width: ${props => props.blockSize};

  .grid-row,
  .grid-col {
    padding: 0;
  }

  ${props => props.theme.below.desktop`
        margin-bottom: -4rem;

        padding: 0 2rem;
    `}

  ${props => props.theme.above.desktop`
        margin-top: 0;
    `}
`

const StyledVideo = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  ${props => props.theme.above.desktop`
        height: 70rem;
    `}
`

const StyledVimeo = styled(ReactPlayer)`
  height: 100%;
  width: 100%;

  iframe {
    width: 100%;
  }

  ${props => props.theme.above.desktop`
        iframe {
            height: 100%;
        }
    `}
`

const StyledImage = styled(Img)`
  position: absolute !important;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;

  opacity: ${props => (props.hidden ? 0 : 1)};
`

const InlineVideo = ({
  className,
  layout,
  backgroundColor,
  data: { vimeoId },
}) => {
  const [ready, setReady] = useState(false)

  // useEffect(() => {
  //     const intervalId = setInterval(() => {
  //         if (videoEl.current.readyState >= 3) {
  //             videoEl.current.play()
  //             setReady(true)
  //             clearInterval(intervalId)
  //         }
  //     }, 500)
  //     return () => clearInterval(intervalId)
  // }, [])

  const [wrapperRef, wrapperInView] = useInView({
    triggerOnce: true,
    threshold: 0.75,
  })

  const [videoRef, videoInView] = useInView({
    threshold: 0.5,
  })

  return (
    <Wrapper
      ref={wrapperRef}
      className={className}
      blockSize={layout.blockSize}
    >
      <Grid type="row">
        <Grid type="column" columns={12} offset={0}>
          <StyledVideo ref={videoRef}>
            <StyledVimeo
              url={`https://vimeo.com/${vimeoId}`}
              width="100%"
              height="100%"
              loop={true}
            />
            <ImageOverlay
              inView={wrapperInView}
              backgroundColor={backgroundColor}
            />
          </StyledVideo>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default InlineVideo
