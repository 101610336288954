import React from 'react'

import NumbersCarousel from './Carousels/NumbersCarousel'
import ImagesCarousel from './Carousels/ImagesCarousel'
import QuotesCarousel from './Carousels/QuotesCarousel'

const Carousel = ({ 
    data,
    backgroundColor
}) => {
    if (data.type == 'Quotes') {
        return <QuotesCarousel data={data} />
    } else if (data.type == 'Fade') {
        return <ImagesCarousel data={data} type='fade' backgroundColor={backgroundColor} />
    } else if (data.type == 'Numbers') {
        return <NumbersCarousel data={data} />
    } else if (data.type == 'Multiple') {
        return <ImagesCarousel data={data} type='multiple' />
    } else {
        return <p>Fallback</p>
    }
    
}

export default Carousel;
