import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import theme from './../styles/theme'

import Grid from './Grid'
import TextRenderer from './TextRenderer'

import { getColor } from './../utils/helpers'

const Wrapper = styled.div`
    position: relative;

    width: 100%;

    ${props => props.theme.above.desktop`
        display: flex;
        flex-direction: row-reverse;

        ${props.imagePosition == 'Bottom Left' ? `
            padding-bottom: ${props.padding / 10}%;
        ` : `
            padding-top: ${props.padding / 10}%;
        `}
    `}
`

const TextWrapper = styled(motion.div)`
    display: block;

    position: relative;

    width: 100%;
    height: 100%;

    background-color: ${props => props.backgroundColor};

    padding: 4rem 2rem;

    ${props => props.theme.above.desktop`
        position: absolute;
        padding: 0;

        ${props.imagePosition == 'Bottom Left' ? `
            bottom: 0;
            left: 0;
        ` : `
            top: 0;
            right: 0;
        `}

        width: 60vw;
        max-width: 100rem;
        min-width: 80rem;

        height: auto;
        
        margin: 0 auto;
        padding: 10rem;
    `}

    ${props => props.theme.above.wide`
        padding: 15rem;
    `}
`

const ImageWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    overflow: hidden;
`

const Overlay = styled(motion.div)`
    position: absolute;
    
    top: 0;
    left: 0;

    transform-origin: right;
    
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.colors.white};
`

const StyledImg = styled(Img)`
    width: 100%;
    height: 100%;
    
    max-height: 100rem;

    object-fit: cover;
`

const BackgroundText = ({
    lang,
    data
}) => {

    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    })

    return (
        <Wrapper 
            ref={ref} 
            imagePosition={data.imagePosition} 
            padding={data.padding}
        >
            <ImageWrapper>
                <StyledImg fluid={data.image.fluid} alt='AES International' />
                <Overlay
                    animate={inView ? 'after' : 'before'}
                    variants={{
                        before: {
                            scaleX: 1
                        },
                        after: {
                            scaleX: 0
                        }
                    }}
                    transition={{
                        duration: 0.35,
                        damping: 100,
                        ease: 'circOut'
                    }}
                />
            </ImageWrapper>
            <TextWrapper 
                imagePosition={data.imagePosition} 
                padding={data.padding} 
                backgroundColor={getColor(theme, data.textBackground)}
                animate={inView ? 'after' : 'before'}
                variants={{
                    before: {
                        opacity: 0,
                        y: 150
                    },
                    after: {
                        opacity: 1,
                        y: 0
                    }
                }}
                transition={{
                    delay: 0.35,
                    duration: 0.35,
                    damping: 100,
                    ease: 'circOut'
                }}
            >
                <Grid type='row'>
                    <Grid type='column' columns={12} offset={0}>
                        <TextRenderer lang={lang} data={data.content} />
                    </Grid>
                </Grid>
            </TextWrapper>
        </Wrapper>
    )
}

export default BackgroundText
