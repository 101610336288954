import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Grid from './Grid'
import ImageOverlay from './Shared/ImageOverlay'

const Wrapper = styled.div`
    position: relative;

    width: ${props => props.blockSize};

    .grid-row, .grid-col {
        padding: 0;
    }

    ${props => props.theme.below.desktop`
        margin-bottom: -4rem;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    object-fit: cover;
`

const Image = ({
    className,
    layout,
    backgroundColor,
    data
}) => {

    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    })

    return (
        <Wrapper ref={ref} className={className} blockSize={layout.blockSize}>
            <Grid type='row'>
                <Grid type='column' columns={12} offset={0}>
                    <StyledImage
                        fluid={data.image.fluid}
                        alt={data.image.title}
                    />
                    <ImageOverlay 
                        inView={inView} 
                        backgroundColor={backgroundColor}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default Image
