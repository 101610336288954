import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { AnimatePresence, motion } from 'framer-motion'

import Grid from './Grid'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const Navigation = styled(motion.nav)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    margin-bottom: 4rem;

    ${props => props.theme.above.desktop`
        flex-direction: row;

        margin-bottom: 10rem;
    `}
`

const Button = styled.button`
    font-family: ${props => props.theme.fontFamily.estiLight};
    font-size: 1.5rem;
    line-height: 3rem;

    color: currentColor;

    cursor: pointer;

    opacity: 0.25;

    transition: opacity 0.25s ${props => props.theme.transitions.cubic};

    &:hover {
        opacity: 1;
    }

    ${props => props.active && `
        opacity: 1;
    `}

    ${props => props.theme.above.desktop`
        font-size: 2rem;
        line-height: 4rem;

        &:not(:first-of-type) {
            margin-left: 3rem;
        }
    `}
`

const List = styled(motion.ul)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    position: relative;

    width: 100%;
    
    margin: 0 auto;

    &:after {
        content: '';
        position: absolute;
        
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border: 2px solid ${props => props.theme.colors.greyDark};
    }

    ${props => props.theme.above.desktop`
        grid-template-columns: repeat(4, 1fr);
    `}
`

const Item = styled(motion.li)`
    position: relative;
    
    padding-top: 100%;

    border: 1px solid rgba(255, 255, 255, 0.15);
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;

    text-align: center;

    padding: 4rem;

    ${props => props.theme.above.desktop`
        padding: 10rem;
    `}
`

const Logo = styled(Img)`
    width: 100%;
    height: 100%;

    object-fit: contain;
`

const Suppliers = ({
    data
}) => {

    const [activeCategory, setActiveCategory] = useState(data.categories[0])

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    })

    return (
        <Wrapper ref={ref}>
            <Grid type='row'>
                <Grid type='column' columns={10} offset={1}>
                    <Navigation
                        animate={inView ? 'after' : 'before'}
                        initial={{
                            opacity: 0
                        }}
                        variants={{
                            before: {
                                opacity: 0,
                                y: 50
                            },
                            after: {
                                opacity: 1,
                                y: 0
                            },
                        }}
                        transition={{
                            duration: 0.6,
                            damping: 100,
                            ease: 'circOut'
                        }}
                    >
                        {data.categories.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    active={activeCategory.title == item.title}
                                    onClick={() => setActiveCategory(data.categories[index])}
                                >{item.title}</Button>
                            )
                        })}
                    </Navigation>
                    <List
                        animate={inView ? 'after' : 'before'}
                        initial={{
                            opacity: 0
                        }}
                        variants={{
                            before: {
                                opacity: 0,
                                y: 50
                            },
                            after: {
                                opacity: 1,
                                y: 0
                            }
                        }}
                        transition={{
                            delay: 0.35,
                            damping: 100,
                            ease: 'circOut',
                            staggerChildren: 0.2
                        }}
                    >
                        {activeCategory.suppliers.map((item, index) =>
                            <Item
                                key={index}
                                initial={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1
                                }}
                                transition={{
                                    damping: 100,
                                    ease: 'circOut',
                                }}
                            >
                                <Inner>
                                    <Logo fluid={item.fluid} alt={item.title} />
                                </Inner>
                            </Item>
                        )}
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default Suppliers
