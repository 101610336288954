import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import TextRenderer from "./TextRenderer"
import Grid from "./Grid"

import { padLeft } from "../utils/helpers"

const Wrapper = styled.div`
  width: 100%;
`

const StyledList = styled(motion.ul)`
  width: 100%;

  ${props =>
    props.type == "Grid"
      ? `
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;

        padding: 0 2rem;
    `
      : `
        display: flex;
        flex-direction: column;

        margin-top: 2rem;

        li {
            &:not(:last-of-type) {
                margin-bottom: 2rem;
            }
        }
    `}

  ${props => props.theme.above.desktop`
        ${
          props.type == "Grid"
            ? `
            grid-template-columns: repeat(${props.columnSize}, 1fr);
            grid-gap: 5rem;

            max-width: 133rem;
            margin: 0 auto;

            padding: 0;
        `
            : `
            li {
                &:not(:last-of-type) {
                    margin-bottom: 10rem;

                    .text-block {
                        margin-bottom: 0;
                    }
                }
            }
        `
        }
    `}
`

const ListItem = styled(motion.li)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const Number = styled.span`
  display: none;

  margin-right: 1rem;

  font-family: ${props => props.theme.fontFamily.proximaRegular};
  font-size: 1.6rem;
  line-height: 2rem;

  color: ${props => props.theme.colors.greyText};

  ${props => props.theme.above.desktop`
        display: block;

        margin-right: 0;
        margin-bottom: 1rem;

        font-size: 1.4rem;
        line-height: 2rem;
    `}
`

const Title = styled.h3`
  display: none;

  font-family: ${props => props.theme.fontFamily.proximaBold};

  ${props => props.theme.above.desktop`
        display: block;

        font-size: 3.5rem;
        line-height: 4rem;
    `}
`

const StyledTextRenderer = styled(TextRenderer)`
  p {
    a {
      color: ${props => props.theme.colors.greyText};

      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }

  ${props => props.theme.above.desktop`
        &:first-of-type {
            margin-bottom: 0 !important;
        }
    `}
`

const variants = {
  before: {
    opacity: 0,
    y: 120,
  },
  after: {
    opacity: 1,
    y: 0,
  },
}

const transition = {
  duration: 0.5,
  damping: 100,
  ease: "circOut",
}

const List = ({ data, lang, layout, backgroundColor }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  })

  return (
    <Wrapper ref={ref}>
      <StyledList
        type={data.type}
        columnSize={layout.columns.split("/")[1]}
        animate={inView ? "after" : "before"}
        variants={{
          before: {},
          after: {
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
      >
        {data?.items?.map((item, index) => {
          if (item.__typename == "ContentfulComponentListItem") {
            return (
              <ListItem key={index} variants={variants} transition={transition}>
                <Grid type="row">
                  <Grid type="column" columns={3} offset={1}>
                    <Title>
                      <Number>{padLeft(index + 1)}</Number>
                      <span>{item.title}</span>
                    </Title>
                  </Grid>
                  <Grid type="column" columns={6} offset={1}>
                    <StyledTextRenderer
                      lang={lang}
                      data={item.content}
                      animate={inView ? "after" : "before"}
                      backgroundColor={backgroundColor}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )
          } else {
            return (
              <ListItem key={index} variants={variants} transition={transition}>
                <StyledTextRenderer
                  lang={lang}
                  data={item.content}
                  animate={inView ? "after" : "before"}
                  backgroundColor={backgroundColor}
                />
              </ListItem>
            )
          }
        })}
      </StyledList>
    </Wrapper>
  )
}

export default List
