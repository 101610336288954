import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Grid from './Grid'
import { generatePath } from '../utils/helpers'

const Wrapper = styled(motion.div)`
    position: relative;

    width: 100%;

    color: currentColor;

    ${props => props.alignment == 'Center' ? `
        margin: 0 auto;
        
        text-align: center;

        .large-title {
            text-align: center;
        }
    ` : `
        margin: 0;

        text-align: left;
    `}

    ${props => props.theme.above.desktop`
        ${props.alignment == 'Center' ? `
            margin: 0 auto;

            text-align: center;

            .large-title {
                text-align: left;
            }
        ` : `
            margin: 0;

            text-align: left;
        `}
    `}
`

const LargeTitleStyles = css`
    display: inline-block;

    position: relative;

    color: currentColor;

    text-align: left;

    ${props => props.theme.above.desktop`
        padding: 0;

        .line {
            &:nth-of-type(1) {
                margin-left: 5rem;
            }

            &:nth-of-type(2) {
                .subtitle {
                    display: none;
                }
            }
        }
    `}
`

const PageTitle = styled(motion.h1)`
    ${LargeTitleStyles}

    margin-top: 5rem;
    margin-bottom: 2rem;

    padding: 0 2rem;

    .line {
        overflow: hidden;
    }

    ${props => props.theme.above.desktop`
        margin-top: ${props.icon == 'true' ? '15rem' : '35rem'};
        margin-bottom: 5rem;

        padding: 0;

        ${props.alignment == 'Left' && `
            margin-left: 20rem;
        `}
    `}

    ${props => props.theme.above.extraWide`
        margin-left: 0;

        left: 50%;

        transform: translateX(-50%);
    `}
`

const LargeTitle = styled.h2`
    ${LargeTitleStyles}

    margin-bottom: 2rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 5rem;
    `}
`

const StyledIcon = styled(motion.img)`
    display: block;

    width: 7.5rem;
    height: 7.5rem;

    margin-bottom: 3rem;

    ${props => props.theme.above.desktop`
        width: 15rem;
        height: 15rem;

        margin-left: 10rem;
        margin-bottom: 5rem;
    `}
`

const SubtitleStyles = css`
    font-family: ${props => props.theme.fontFamily.proximaRegular};
    font-size: 1.6rem;

    opacity: 0.35;
`

const TitleLine = styled.span`
    display: block;

    position: relative;

    white-space: nowrap;

    ${props => props.theme.below.desktop`
        overflow: hidden;
    `}
`

const TitleSub = styled.span`
    display: none;

    position: absolute;

    top: -0.5rem;
    left: 0;

    transform: translateX(-110%);

    ${SubtitleStyles}

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const TitlePart = styled(motion.span)`
    display: block;
    position: relative;
`

const MediumTitle = styled.h3`
    width: 100%;
    max-width: 90rem;

    ${props => props.withLink == 'false' && `
        margin-bottom: 2rem;
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 2rem;

        ${props.withLink == 'false' && `
            margin-bottom: 10rem;
        `}
    `}
`

const MediumSubtitle = styled.span`
    display: block;

    margin-bottom: 1rem;

    ${SubtitleStyles}
`

const MediumSubtitleLink = styled(Link)`
    display: inline-block;

    position: relative;

    margin-bottom: 2rem;

    font-family: ${props => props.theme.fontFamily.proximaRegular};
    font-size: 1.7rem;
    line-height: 3rem;

    transition: color 0.23s ${props => props.theme.transitions.cubic};

    ${props => props.theme.underline('currentColor', 0.1)};

    &.link {
        color: ${props => props.theme.colors.greyText};

        &:hover {
            color: ${props => props.theme.colors.white};
        }
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 10rem;

        font-size: 2rem;
		line-height: 3.4rem;
    `}
`

const variants = {
    before: {
        skewX: -4,
        opacity: 0,
        y: 120
    },
    after: {
        skewX: 0,
        opacity: 1,
        y: 0
    }
}

const transition = {
    duration: 0.5,
    damping: 100,
    ease: "circOut"
}

const Title = ({
    className,
    lang,
    data: {
        icon,
        titleBlock,
        type,
        subtitle,
        subtitleLink,
        alignment
    }
}) => {

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.25,
    })

    const animate = inView ? 'after': 'before'

    if (type == 'Page Title') {
        return (
            <Wrapper
                ref={ref}
                className={className}
                type={type}
                alignment={alignment}
            >
                <PageTitle 
                    alignment={alignment} 
                    className='page-title'
                    icon={icon ? 'true' : 'false'}
                    animate={inView ? 'after' : 'before'}
                    variants={{
                        before: {},
                        after: {
                            transition: {
                                staggerChildren: 0.15
                            }
                        }
                    }}
                >
                    {icon && (
                        <StyledIcon
                            src={icon.file.url}
                            alt='AES International'
                            variants={{
                                before: {
                                    scale: 0,
                                    opacity: 0
                                },
                                after: {
                                    scale: 1,
                                    opacity: 1
                                }
                            }}
                            transition={transition}
                        />
                    )}
                    {documentToReactComponents(titleBlock.json, {
                        renderNode: {
                            [BLOCKS.PARAGRAPH]: (node) => {
                                return (
                                    <TitleLine className='line'>
                                        <TitlePart
                                            className='part'
                                            variants={{
                                                before: {
                                                    top: 250,
                                                    skewX: 3
                                                },
                                                after: {
                                                    top: 0,
                                                    skewX: 0
                                                }
                                            }}
                                            transition={transition}
                                        >{node.content[0].value}</TitlePart>
                                    </TitleLine>
                                )
                            }
                        }
                    })}
                </PageTitle>
            </Wrapper>
        )
    } else if (type == 'Large') {
        return (
            <Wrapper
                ref={ref}
                className={className}
                type={type}
                alignment={alignment}
                animate={animate}
                variants={variants}
                transition={transition}
            >
                <LargeTitle className='large-title' alignment={alignment} icon={icon ? 'true' : 'false'}>
                    {icon && <StyledIcon src={icon.file.url} alt='AES International' />}
                    {documentToReactComponents(titleBlock.json, {
                        renderNode: {
                            [BLOCKS.PARAGRAPH]: (node) => {
                                return (
                                    <TitleLine className='line'>
                                        {subtitle && <TitleSub className='subtitle'>{subtitle}</TitleSub>}
                                        <TitlePart className='part'>{node.content[0].value}</TitlePart>
                                    </TitleLine>
                                )
                            }
                        }
                    })}
                </LargeTitle>
            </Wrapper>
        )
    } else {
        return (
            <Grid type='row'>
                <Grid type='column' columns={10} offset={1}>
                    <Wrapper
                        ref={ref}
                        className={className}
                        type={type}
                        alignment={alignment}
                        animate={animate}
                        variants={variants}
                        transition={transition}
                    >
                        {subtitle && !subtitleLink && <MediumSubtitle>{subtitle}</MediumSubtitle>}
                        <MediumTitle className='medium-title' withLink={subtitleLink ? 'true' : 'false'}>
                            {documentToReactComponents(titleBlock.json, {
                                renderNode: {
                                    [BLOCKS.PARAGRAPH]: (node, children) => {
                                        return (node.content[0].value)
                                    }
                                }
                            })}
                        </MediumTitle>
                        {subtitle && subtitleLink && <MediumSubtitleLink className='link' to={generatePath(lang, subtitleLink)}>{subtitle}</MediumSubtitleLink>}
                    </Wrapper>
                </Grid>
            </Grid>
        )
    }
}

export default Title
