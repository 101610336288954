import React from 'react'
import styled from 'styled-components'

import TextRenderer from './TextRenderer'

const StyledThumbnail = styled.div`
    width: ${props => props.blockSize};
`

const Thumbnail = ({
    layout,
    lang,
    data: {
        content
    }
}) => {
    return (
        <StyledThumbnail blockSize={layout.blockSize}>
            <TextRenderer 
                lang={lang} 
                data={content} 
            />
        </StyledThumbnail>
    )
}

export default Thumbnail
